import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    count: 0,
    company: 0,
    companydata: {},
    user: '',
    userdata: {},
    isAuth: false,
    treeData: [], //Arbol de archivo
    open: [], // Nodos abiertos del árbol de archivo de DOCUMENTOS
    active: [], // Nodos activos
    drawer: false, // Ver menú de opciones de Dashboard,
    modal: false, // Ver modal dialog from Dashboard 
    logo: '', // Url del logo
    item: {}, // Item para Pqrs
    sessionId: null, //Id de la sesión
    prestamos: false,
    reclamaciones: false
  },
  mutations: {
    increment: state => state.count++,
    decrement: state => state.count--,
    setCompany: (state, number) => state.company = number,
    setCompanyData: (state, values) => state.companydata = values,
    setUser: (state, name) => state.user = name,
    setUserdata: (state, values) => state.userdata = values,
    setIsAuth: (state, value) => state.isAuth = value,
    setTreeData: (state, values) => state.treeData = values,
    setOpen: (state, values) => state.open = values,
    setActive: (state, values) => state.active = values,
    setDrawer: (state, value) => state.drawer = value,
    setModal: (state, value) => state.modal = value,
    setLogo: (state, value) => state.logo = value,
    setItem: (state, values) => state.item = values,
    setSessionId: (state, value) => state.sessionId = value,
    setPrestamos: (state, value) => state.prestamos = value,    
    setReclamaciones: (state, value) => state.reclamaciones = value    
  },
  getters: {
    getCompany: state => state.company
  },
  plugins: [createPersistedState()],
  actions: {
  },
  modules: {
  }
})
