let widths = ["auto", "auto", "auto", "auto", "auto", "auto", "*", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "*"];

export default function ({ data = {} }) {

    return {
        pageSize: 'TABLOID',
        pageOrientation: 'landscape',
        pageMargins: 10,

        content: [
            {
                fontSize: 7,
                layout: 'lightHorizontalLines',
                table: {
                    headerRows: 1,
                    widths,
                    body: data.body
                }
            }
        ]
    }
}