export default function ({ data: { body: data } } = {}) {
  const fillTable = () => {
      let rows = [];
      console.log('DATA.BODY');
      console.log(JSON.stringify(data.body));
      //let quantity = data.body.length > 20 ? data.body.length : 20
      let quantity = Object.keys(data.body).length;
      console.log(quantity);
      debugger;

      for (var i in Array(quantity).fill('')) {
          let columns = Array(5).fill('')

          columns[0] = { text: data.body[i]?.hjc_fecha || '', alignment: 'left', }
          columns[1] = { text: data.body[i]?.hjc_descripcion_doc || '', alignment: 'left', }
          columns[2] = { text: data.body[i]?.hjc_folio_ini || '', alignment: 'left', }
          columns[3] = { text: data.body[i]?.hjc_folio_fin || '', alignment: 'left', }
          columns[4] = { text: data.body[i]?.hjc_area_responsable || '', alignment: 'left', }

          rows.push(columns)
      }

      return rows
  }

  return {
      pageMargins: [20, 20],
      defaultStyle: {
          fontSize: 9,
      },
      content: [
          {
              table: {
                  widths: [140, 250, 140],
                  body: [
                      [
                          {
                              image: data?.logo,
                              width: 100,
                              alignment: 'center'
                          },
                          {
                              margin: [10, 10, 10, 10],
                              text: 'HOJA DE CONTROL',
                              alignment: 'center',
                              bold: true,
                              fontSize: 14
                          },
                          {
                              fontSize: 10,
                              margin: [5, 5, 5, 5],
                              text: ''
                              /*
                              columns: [
                                  {
                                      width: 50,
                                      stack: [
                                          {
                                              text: 'Código:',
                                              bold: true
                                          },
                                          {
                                              text: 'Versión:',
                                              bold: true
                                          },
                                          {
                                              text: 'Fecha:',
                                              bold: true
                                          },
                                          {
                                              text: 'Página:',
                                              bold: true
                                          }
                                      ]
                                  },
                                  {
                                      stack: [
                                          'FR-GDC-ARC-007',
                                          '01',
                                          '04-04-2022',
                                          '1 de 1'
                                      ]
                                  }
                              ]
                              */
                          }
                      ],
                  ]
              }
          },
          
          {
              table: {
                  widths: [140, 172, 35, 25, 70, 61],
                  body: [
                      [
                          {
                              text: 'Unidad Productora:',
                              alignment: 'left',
                              bold: true
                          },
                          {
                            text: data.header?.dep_nmbre,
                            alignment: 'left',
                          },        
                          {
                            text: 'Código:',
                            alignment: 'left',
                            bold: true
                          },                                             
                          {
                            text: data.header?.dep_cdgo,
                            alignment: 'left',
                          },                            
                          {
                            text: 'Fecha apertura de hoja:',
                            alignment: 'left',
                            bold: true
                          },                                             
                          ''
                      ],
                      [
                          {
                              text: 'Serie:',
                              alignment: 'left',
                              bold: true
                          },
                          {
                            text: data.header?.ser_nmbre,
                            alignment: 'left',
                          },
                          {
                            text: 'Código:',
                            alignment: 'left',
                            bold: true
                          },
                          {
                            text: data.header?.ser_cdgo,
                            alignment: 'left',
                          },          
                          {
                            text: 'Fecha apertura expediente::',
                            alignment: 'left',
                            bold: true
                          },                  
                          {
                            text: data.header?.dcm_fchaini,
                            alignment: 'left',
                          }
                      ],
                      [
                        {
                            text: 'Subserie:',
                            alignment: 'left',
                            bold: true,
                        },
                        '',
                        {
                          text: 'Código:',
                          alignment: 'left',
                          bold: true
                        },
                        '',        
                        {
                          text: 'Tomo No.:',
                          alignment: 'left',
                          bold: true
                        },                    
                        {
                          text: data.header?.dcm_tomo,
                          alignment: 'left',
                        }
                    ],
                    [
                      {
                          text: 'Nombre del expediente:',
                          alignment: 'left',
                          bold: true
                      },
                      {
                        text: data.header?.dcm_asunto,
                        alignment: 'left',
                      },
                      '',
                      '',
                      {
                        text: 'No. Folios del Tomo:',
                        alignment: 'left',
                        bold: true
                      },
                      {
                        text: data.header?.dcm_nflios,
                        alignment: 'left',
                      }
                    ],
                  ],
              }
          },

          {
            table: {
              widths: [ 548 ],
              body: [
                [
                  {
                    text: '',
                    alignment: 'center',
                    margin: [10, 10, 10, 10]
                  }
                ]
              ]
            }
          },

          {
              table: {
                  widths: [65, 225, 51, 51, 120],
                  heights: function (row, i) {
                      if (row > 1) return 25;
                  },
                  body: [
                      [
                          {
                              text: 'Fecha Docum.',
                              alignment: 'center',
                              bold: true
                          },                            
                          {
                              text: 'Descripción del documento ',
                              alignment: 'center',
                              bold: true
                          },
                          {
                            text: 'Folio Inicial',
                            alignment: 'center',
                            bold: true
                          },
                          {
                              text: 'Folio Final',
                              alignment: 'center',
                              bold: true
                          },
                          {
                              text: 'Área responsable',
                              alignment: 'center',
                              bold: true
                          },
                      ],
                      ...fillTable()
                  ]
              }
          }
      ]
  }
}