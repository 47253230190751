// plugins to Excel
import { default as Excel } from "exceljs";
import FileSaver from "file-saver";

// plugins to Pdf

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

import formatos from './FormatoReports/index'

export default class {
    #callback = null
    #callback_error = null

    #fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    #fileExtension = ".xlsx";

    constructor(data = {}) {
        this.tipo = data.tipo || 'excel'
        this.data_impresion = data.datos || []
        this.name = data.name || `report-${Date.now()}`
        this.formato = data.formato || null
        this.order_data = data.order_data ?? true
    }

    default_msj(status = 0, message = '') {
        return { status, message }
    }

    init(success = null, error = null) {
        let msj = this.default_msj()

        if (typeof success != 'function') {
            msj.status = -1
            msj.message = 'No se ha definido parametro callback'
            console.error(msj)
        } else if (typeof error != 'function') {
            msj.status = -1
            msj.message = 'No se ha definido parametro callback error'
            console.error(msj)
        } else {
            this.#callback = success
            this.#callback_error = error

            switch (this.tipo) {
                case 'excel':
                    this.Excel()
                    break;
                case 'pdf':
                    this.Pdf()
                    break
                case 'csv':
                    this.Csv()
                    break
                default:
                    this.#callback_error(this.default_msj(-1, 'No se ha definido proceso'))
                    break;
            }
        }
    }

    async Excel() {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("Mi hoja");

        let { columns, body } = await this.order_datos()

        worksheet.addTable({
            name: 'table',
            ref: 'A1',
            headerRow: true,
            totalsRow: false,
            style: {
                theme: 'TableStyleMedium16',
                showRowStripes: true,
            },
            columns: columns,
            rows: body,
        });

        worksheet.columns.forEach(column => {
            let width = 0;
            let id_columna = column._number;
            let config_column = this.data_impresion.header[id_columna - 1] || false

            column.eachCell({ includeEmpty: false }, (cell) => {
                let columna = null;
                let parent_columna = null;
                let col2 = cell._address.substring(1, 2);

                if (isNaN(parseFloat(col2))) {
                    columna = String.fromCharCode(96 + id_columna).toUpperCase();
                    parent_columna = cell._address.substr(0, 2);
                } else {
                    columna = String.fromCharCode(96 + id_columna).toUpperCase();
                    parent_columna = cell._address.substr(0, 1);
                }

                if (
                    columna == parent_columna &&
                    config_column &&
                    config_column.format &&
                    config_column.format == "date"
                ) {
                    cell.numFmt = "dd/mm/yyyy";
                    console.log(config_column.format)
                }

                cell.alignment = {
                    vertical: "middle",
                };

                let actual = cell.value ? (cell.value.length || 10) + 5 : 5;
                width = actual > width ? actual : width;
            });

            column.width = column._number == 1 && width < 20 ? 20 : width;
        })

        try {
            if (typeof window == "undefined") {
                let name = `${this.name}.xlsx`
                await workbook.xlsx.writeFile(name);
            } else {
                const buffer = await workbook.xlsx.writeBuffer()
                const blob = new Blob([buffer], { type: this.#fileType });
                FileSaver.saveAs(blob, this.name + this.#fileExtension);
            }


            this.#callback(this.default_msj(0, 'Reporte generado'))

        } catch (error) {
            this.#callback_error(this.default_msj(-1, error))
        }
    }

    async Pdf() {
        let msj = this.default_msj(0, "Reporte generado")

        if (!this.formato) {
            msj.status = 1
            msj.message = "Formato impresion no definido"
            return this.#callback_error(msj)
        }

        try {
            let body = null

            if (this.order_data) {
                body = await (await this.order_datos()).body
            } else {
                body = this.data_impresion
            }

            let imprimir = formatos[this.formato];
            let dd = imprimir({ data: { body } })

            pdfMake.createPdf(dd).open()
            this.#callback(msj)
        } catch (error) {
            msj.status = 1
            msj.message = error

            this.#callback_error(msj)
        }
    }

    async Csv() {

        try {
            const element = document.createElement('a');
            let { body_csv } = await this.order_datos()

            let csvFile = new Blob([body_csv], { type: "text/plain;charset=UTF-8", encoding: "UTF-8" });
            const filename = `${this.name}${new Date().getTime()}.csv`

            element.download = filename
            let url = window.URL.createObjectURL(csvFile);
            element.href = url;

            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);

            this.#callback(this.default_msj(0, 'Reporte generado'))
        } catch (error) {
            console.log("Error generando csv: ", error)
            this.#callback_error(this.default_msj(-1, 'Error generando csv'))
        }

    }

    async order_datos() {
        let { header, content } = this.data_impresion;
        let body = [];
        let body_csv = ""
        let delimiter = ','


        content.forEach((item, index) => {
            body.push([])

            for (const head of header) {
                let data = null
                let point = head.pointer.indexOf(".") > 0 ? head.pointer.split(".") : "";

                if (point) data = item[point[0]][point[1]] || "";
                else data = item[head.pointer]

                if (head.format == 'date') data = formatDate(data);

                if (this.tipo != 'csv') body[index].push(data);
                else body_csv += `${JSON.stringify(data)}${delimiter}`
            }

            if (this.tipo == 'csv') body_csv = body_csv.slice(0, -1) + '\n';

        });

        if (this.tipo == 'pdf') {
            let encabezado = []

            header.forEach(item => {
                encabezado.push(item.name)
            })

            body.unshift(encabezado)
        }

        return { body, columns: header, body_csv }
    }
}

const formatDate = (data) => {
    let date = new Date(data);
    date.setDate(date.getDate() + 1)

    let formatDate = date.toLocaleDateString('es-CO', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    })

    return formatDate.split('/').reverse().join('/')
}