import Impresion from './impresion'

export const global = {
    async orderData(data = []) {

        let [array, cod, name] = data;
        let new_data = [];

        for await (const item of array) {
            new_data.push({
                text: `${item[cod]} - ${item[name]}`,
                value: item._id,
            });
        }

        return new_data;
    },
    Impresion
}